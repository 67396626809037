import React, { ReactElement } from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';

import { PageInfo, pageInfoByLocale, updateSEO } from '../queries/page-info-helpers';
import { getLocalizedValue } from '../components/locale-context';
import { reduceLocales } from '../libs/locale';
import { PageSEO } from '../libs/seo';
import { LocationInfo } from '../libs/location';

import { InterractiveSection } from "@chweb/commonui";
import DistributionMap from '../components/distribution-map';
import {
  ContactCards,
  ContactRegion,
  ContactRegions,
  Contact
} from '../components/contact-cards';

import DistributionData from '../sitecontent/b2b/distribution-data.json';
import { PageInfoData } from '../queries/fragment-page-info';

interface Distributor {
  name: string,
  regions: string[],
  nationwide: {
    exclude: string[]
  },
  url: string,
  address1: string,
  address2: string,
  phones: string[]
}

interface Region {
  region: string,
  data: {
    locale: string,
    name: string
  }[],
  coordinates: number[]
}

function getSEO (location: LocationInfo, pageInfo: PageInfo): PageSEO {
  const seo = PageSEO.fromLocation(location);
  updateSEO(seo, pageInfo);
  return seo;
}

function getRegions (infoLookup: RegionInfoLookup, regions: string[]): ContactRegion[] {
  return regions.map(r => {
    let region = infoLookup[r].name;

    const REGION_SUBREGION_EXPECTED_PARTS = 2;
    const PART_REGION = 1;
    const PART_SUBREGION = 0;
    const regionParts = region.split(',');
    let subregion = null;
    if (regionParts.length === REGION_SUBREGION_EXPECTED_PARTS) {
      subregion = regionParts[PART_SUBREGION].trim();
      region = regionParts[PART_REGION].trim();
    }
    return new ContactRegion(region, subregion);
  });
}

type RegionInfoLookup = { [key: string]: { name: string, locale: string } };

function createContacts (locale: string): Contact[] {
  const regionInfoLookupMulti:  { [key: string]: { name: string, locale: string }[] }= {};
  const regions: Region[]  = DistributionData.regions;
  regions.reduce((acc, r) => {
    acc[r.region] = r.data;
    return acc;
  }, regionInfoLookupMulti);

  const regionInfoLookup: RegionInfoLookup = reduceLocales<unknown, RegionInfoLookup>(regionInfoLookupMulti, locale) as RegionInfoLookup;

  const distributors: Distributor[] = DistributionData.distributors;
  return distributors.map(d => {
    const regionList = getRegions(regionInfoLookup, d.regions);
    let regions = null;
    if (d.nationwide) {
      const excludeList = getRegions(regionInfoLookup, d.nationwide.exclude);
      regions = new ContactRegions(regionList, true, excludeList);
    } else {
      regions = new ContactRegions(regionList);
    }
    const contact = Contact.fromObject(d, regions);
    return contact;
  });
}

interface DomaineProps {
  data: PageDistributionData,
  pageContext: { slug: string, locale: string},
  location: LocationInfo
}

export default function Domaine ({ data, pageContext, location }: DomaineProps): ReactElement {
  const { locale } = pageContext;
  const localPageInfo = getLocalizedValue(pageInfoByLocale(data.page), locale);
  const seo = getSEO(location, localPageInfo);

  const contactInfos = createContacts(locale);

  return (
    <Layout locale={locale} location={location} title = { localPageInfo.title } seo = { seo }>
      <InterractiveSection title = { localPageInfo.title } >
        <div className = "w3-margin">
          <div className = "w3-padding">
            <DistributionMap />
          </div>
          <div dangerouslySetInnerHTML = {{ __html: data.md.html }} />
          <ContactCards contacts = { contactInfos }/>
        </div>
      </InterractiveSection>
    </Layout>
  );
}

interface PageDistributionData {
  md: {
    html: string
  },
  page: PageInfoData
}

export const query = graphql`
query($locale: String!) {
  md: markdownRemark(fields: {slug: {regex: "/distribution\\.[a-z]{2}/"}, locale: {eq: $locale}}) {
    html
  }
  page: pageIndexYaml(yamlId : {eq: "distribution"}) {
    ...PageInfo
  }
}
`;
