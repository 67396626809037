export type StringStringMap = { [key: string]: string };
export type CleanupMethod = (s: string) => string;

export function objStrSortFunc (a: StringStringMap, b: StringStringMap, fields: string[], fieldCleanupMethod: CleanupMethod = f => f): number {

  // helper to compare against blank/non-existent/null value
  const existanceCompare = (ea: string, eb: string) => {
    if (!ea) {
      return -1;
    }
    if (!eb) {
      return 1;
    }
    return 0;
  };

  // case insensitive compare, after cleanup
  const stringCompare = (sa: string, sb: string) => {
    const aCap = fieldCleanupMethod(sa).toUpperCase();
    const bCap = fieldCleanupMethod(sb).toUpperCase();
    if (aCap < bCap) {
      return -1;
    }
    if (aCap > bCap) {
      return 1;
    }
    return 0;
  };

  // using fields to fallback
  const processFields: string[] = fields.slice();
  while (processFields.length) {
    const field = processFields[0];
    processFields.shift();
    const existanceResult = existanceCompare(a[field], b[field]);
    if (existanceResult === 0) {
      const valueResult = stringCompare(a[field], b[field]);
      if (valueResult !== 0) {
        return valueResult;
      }
    } else {
      return existanceResult;
    }
  }
  return 0;
}
