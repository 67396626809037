import React, { useState, useContext, ReactElement } from "react";

import { Cards } from "@chweb/commonui";
import FilterOptions, { FilterOptionData } from "../components/filter-options";
import { ContactEmail } from "../components/email";
import { AlphabetSelector, isCyrillic } from "../components/alphabet-selector";
import { LocaleContext } from "../components/locale-context";

import { objStrSortFunc, StringStringMap } from "../libs/sort";

import { getContactLabels } from "../queries/get-contact-labels";

import {
  smallcaps as cssSmallCaps
} from "./contact-cards.module.css";

import {
  faEnvelope,
  faGlobe,
  faPhone,
  faFax,
  faExternalLinkAlt,
  faAngleDown,
  faAngleUp
} from "@fortawesome/free-solid-svg-icons";

import { faSkype } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function goToAnchor(elementId: string) {
  document.getElementById(elementId)?.scrollIntoView({ behavior: "smooth" });
}

export class ContactRegion {
  public region: string;
  public subregion: string | null;

  constructor (region: string, subregion: string | null) {
    this.region = region;
    this.subregion = subregion;
  }
}

export class ContactRegions {
  public regions: ContactRegion[];
  public isNationwide: boolean;
  public exceptRegions: ContactRegion[];

  constructor (regions: ContactRegion[], isNationwide = false, exceptRegions: ContactRegion[] = []) {
    this.regions = regions;
    this.isNationwide = isNationwide;
    this.exceptRegions = exceptRegions;
  }
}

interface ContactSortable {
  name: string;
  region: string;
  subregion: string | null;
}

type ContactSourceObject = Partial<Omit<Contact, "regions">>;

export class Contact implements ContactSortable {
  public name: string;
  public regions: ContactRegions;
  public url: string | null;
  public eaddr: string | null;
  public address1: string | null;
  public address2: string | null;
  public phones: string[];
  public fax: string | null;
  public skype: string | null;

  constructor (name: string, regions: ContactRegions) {
    this.name = name;
    this.regions = regions;
    this.url = null;
    this.eaddr = null;
    this.address1 = null;
    this.address2 = null;
    this.phones = [];
    this.fax = null;
    this.skype = null;
  }

  public setRegions (regions: ContactRegions): void {
    this.regions = regions;
  }

  get region (): string {
    return this.getFirstRegion().region;
  }

  get subregion (): string | null {
    const firstRegion = this.getFirstRegion();
    if (firstRegion) {
      return firstRegion.subregion || null;
    }
    return null;
  }

  public getFirstRegion (): ContactRegion {
    const regsContainer = this.regions;
    return regsContainer.regions[0];
  }

  public setUrl (url: string): void {
    this.url = url;
  }

  public setEAddr (eaddr: string): void {
    this.eaddr = eaddr;
  }

  public setAddress (address1: string, address2: (string | null) = null): void {
    this.address1 = address1;
    this.address2 = address2;
  }

  public setPhoneNumber (numbers: string[]): void {
    this.phones = numbers;
  }

  public setFaxNumber (number: string): void {
    this.fax = number;
  }

  public setSkype (skype: string): void {
    this.skype = skype;
  }

  public static fromObject = (object: ContactSourceObject, regions: ContactRegions): Contact => {
    if (!object.name) {
      console.error('Contact must at minumum have a name: ', object);
    }

    const contact = new Contact(object.name || "", regions);

    if (object.url) {
      contact.setUrl(object.url);
    }

    if (object.address1) {
      contact.setAddress(object.address1, object.address2 || null);
    }

    if (object.phones) {
      contact.setPhoneNumber(object.phones);
    }

    if (object.eaddr) {
      contact.setEAddr(object.eaddr);
    }

    if (object.fax) {
      contact.setFaxNumber(object.fax);
    }

    if (object.skype) {
      contact.setSkype(object.skype);
    }

    return contact;
  }
}

type ContactPhonesProps = {
  phoneLabel: string;
  phones: string[];
  faxLabel: string;
  fax: string | null;
};

function ContactPhones ({ phones, fax, phoneLabel, faxLabel }: ContactPhonesProps): ReactElement {
  phones = phones || [];
  fax = fax || '';

  let faxAlreadyMentioned = false;

  const bareFax = fax.replace(/\D/g, '');
  const phoneContent = phones.map(phone => {
    const barePhone = phone.replace(/\D/g, '');
    const isAlsoFax: boolean = !!bareFax && bareFax === barePhone;

    // marking fax as already shown
    faxAlreadyMentioned = isAlsoFax;
    const titleDual = `${phoneLabel} & ${faxLabel}`;
    return <div title = { isAlsoFax ? titleDual : phoneLabel } key = { phone }>
      <span>
        <span><FontAwesomeIcon icon = { faPhone } /> </span>
        { isAlsoFax && <span><FontAwesomeIcon icon = { faFax } title = { faxLabel } /> </span> }
        { phone }
      </span>
    </div>;
  });

  return <div className = "w3-padding-small">
    { phoneContent }
    { fax && !faxAlreadyMentioned &&
        <div title = { faxLabel }>
          <span><FontAwesomeIcon icon = { faFax } /> </span>
          { fax }
        </div>
    }
  </div>;
}

type ContactSkypeProps = {
  skype: string | null;
};

function ContactSkype ({ skype }: ContactSkypeProps): ReactElement | null {
  if (!skype) return null;
  return <div className = "w3-padding-small" title = "Skype">
    <span><FontAwesomeIcon icon = { faSkype } /> { skype }</span>
  </div>;
}

type ContactCardProps = {
  contact: Contact;
};

export function ContactCard ({ contact }: ContactCardProps): ReactElement {
  const [showEaddr, setShow] = useState(false);
  const [showExceptions, setShowExceptions] = useState(false);
  const { locale } = useContext(LocaleContext);

  const labels = getContactLabels(locale);

  const labelNationwide = labels.distrNationwide;

  let subregion = null;
  let exceptions = null;
  if (contact.regions && contact.regions.isNationwide) {
    if (Array.isArray(contact.regions.exceptRegions)) {
      subregion = contact.subregion && <span className = { cssSmallCaps }>
        <a
          title = { showExceptions ? labels.distrExceptionsHide : labels.distrExceptionsShow }
          style = {{ cursor: 'pointer' }}
          onClick = { () => setShowExceptions(!showExceptions) }
        >
          { labelNationwide } { labels.distrNationwideExcept } <FontAwesomeIcon icon = { showExceptions ? faAngleUp : faAngleDown }/>
        </a>
      </span>;
      exceptions = <div className = { showExceptions ? 'w3-show' : 'w3-hide' }>
        { contact.regions.exceptRegions.map(e => <div className = { `w3-text-red ${cssSmallCaps}` } key = { e.subregion } >
          - { e.subregion }
        </div>) }
      </div>;
    } else {
      subregion = contact.subregion && <span className = { cssSmallCaps }>{ labelNationwide }</span>;
    }
  } else if (contact.subregion) {
    subregion = contact.subregion && <span className = { cssSmallCaps }>{ contact.subregion }</span>;
  }

  return <tr><td><div className = "w3-padding w3-card">
    <div className = "w3-panel">
      <div className = "w3-right" style = {{ textAlign: 'right' }}>
        { contact.region && <span className = { cssSmallCaps }>{ contact.region }</span> }
        { contact.region && <br/> }
        { subregion }
        { exceptions }
      </div>
    </div>
    <h4>{ contact.name }</h4>
    <div className = "w3-xlarge w3-right cpcolor-text-brown">
      { contact.url && <a href={contact.url} target="_blank" rel="noreferrer"><FontAwesomeIcon icon = { faGlobe } /> </a> }
      { contact.eaddr && <a onClick = { () => setShow(true) } style = {{ cursor: 'pointer' }}>
        <FontAwesomeIcon icon = { faEnvelope } /> </a> }
    </div>
    <p>
      {
        contact.url && <a href = { contact.url } target="_blank" rel="noreferrer">
          { contact.url.replace('http://', '') }
          <span> <FontAwesomeIcon icon = { faExternalLinkAlt } /></span>
        </a> }
      { contact.url && <br/> }
      { contact.address1 }
      { contact.address1 && <br/> }
      { contact.address2 }
      { contact.address2 && <br/> }
    </p>
    <ContactPhones phones = { contact.phones } fax = { contact.fax }
      phoneLabel = { labels.distrPhone } faxLabel = { labels.distrFax }/>
    <ContactSkype skype = { contact.skype } />
    { contact.eaddr && <CollapsibleEmail visible = { showEaddr } eaddr = { contact.eaddr } label = { labels.distrEmail }/> }
  </div></td></tr>;
}

type CollapsibleEmailProps = {
  label: string;
  eaddr: string;
  visible: boolean;
};

function CollapsibleEmail ({ visible, eaddr, label }: CollapsibleEmailProps): ReactElement {
  return <div className = { visible ? 'w3-show' : 'w3-hide' }>
    <ContactEmail eaddr = { eaddr } label = { label }/>
  </div>;
}

function onAlphabetSelectorClick (letter: string): void {
  goToAnchor(letter);
}

function stripLanguageSpecifics (string: string, locale: string) {
  if (locale === 'fr' && string.match(/^(l[ae] |l['’])/i)) {
  // for French removing le,la,l' prefixes
    string = string.replace(/^(l[ae] |^l['’])/i, '');
  } else if (locale === 'en' && string.match(/^(the |a )/i)) {
    // for english removing the and a
    string = string.replace(/^(the |a )/i, '');
  }
  return string;
}

function bucketContacts (sortOrder: SORT_OPTION | string, contacts: Contact[], locale: string) {
  const bucketKey = sortOrder === SORT_OPTION.BY_COUNTRY.id ? 'region' : 'name';
  const bucketed: {[key: string]: Contact[]} = {};
  
  contacts.reduce((acc, c) => {
    const firstLetter = stripLanguageSpecifics(c[bucketKey].toUpperCase(), locale)[0];
    if (!acc[firstLetter]) {
      acc[firstLetter] = [];
    }
    acc[firstLetter].push(c);
    return acc;
  }, bucketed);

  const sortFields = sortOrder === SORT_OPTION.BY_COUNTRY.id
    ? [ 'region', 'subregion', 'name' ]
    : [ 'name', 'region', 'subregion' ];

  Object.keys(bucketed).forEach(k => {
    bucketed[k] = bucketed[k].sort((a, b) => {
      const aComp = a as unknown as StringStringMap;
      const bComp = b as unknown as StringStringMap;
      return objStrSortFunc(aComp, bComp, sortFields, f => stripLanguageSpecifics(f, locale));
    });
  });

  return bucketed;
}

type CardsSectionProps = {
  letter: string;
  contacts: Contact[];
  available: string[];
};

function CardsSection ({ letter, contacts, available }: CardsSectionProps): ReactElement {
  return <div id={letter}>
    <AlphabetSelector
      selected = { letter }
      available = { available }
      onClick = { onAlphabetSelectorClick }
      isCyrillic = { isCyrillic(letter) }
    />
    <Cards cards={contacts.map((c, i) => <ContactCard key={i} contact = { c } />) } />
  </div>;
}

const SORT_OPTION: { [key: string]: FilterOptionData } = {
  BY_COUNTRY: { id: 'country', label: 'dummy1' },
  BY_NAME: { id: 'name', label: 'dummy2' }
} as const;
type SORT_OPTION = typeof SORT_OPTION[keyof typeof SORT_OPTION];

type ContactCardsProps = {
  contacts: Contact[];
};

export function ContactCards ({ contacts }: ContactCardsProps): ReactElement {
  const { locale } = useContext(LocaleContext);
  const defaultSort = locale === 'zh' ? SORT_OPTION.BY_NAME.id : SORT_OPTION.BY_COUNTRY.id;
  const [sortOrder, setSortOrder] = useState(defaultSort);

  const labels = getContactLabels(locale);
  SORT_OPTION.BY_COUNTRY.label = labels.distrSortByCountry;
  SORT_OPTION.BY_NAME.label = labels.distrSortByName;

  const buckets = bucketContacts(sortOrder, contacts, locale);
  const sortedKeys = Object.keys(buckets).sort();

  const FILTER_WIDGET_KEY = 'sort';
  return <div>
    { locale !== 'zh' && <FilterOptions
      label = { labels.filteredSectionSort }
      options = { Object.keys(SORT_OPTION).map(k => SORT_OPTION[k]) }
      selected = { [ sortOrder ] }
      filterKey = { FILTER_WIDGET_KEY }
      onChange = { newOrder => setSortOrder(newOrder[FILTER_WIDGET_KEY][0]) }
      className = "cpcolor-text-brown"
    />
    }
    {
      sortedKeys.map(k => <CardsSection
        letter = { k }
        contacts = { buckets[k] }
        available = { sortedKeys }
        key = { k }
      />)
    }
  </div>;
}
