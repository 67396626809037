import { useStaticQuery, graphql } from 'gatsby';

import {
  LocalizedLabels,
  parseLabelsFromNodesLocalized as parseLabels
} from '../components/locale-context';

export const getContactLabels = (locale: string): LocalizedLabels => {
  const { labels } = useStaticQuery(
    graphql`{
      labels: allLabelsTranslatedYaml(filter: {name: {in: [
        "filteredSectionSort",
        "distrSortByCountry",
        "distrSortByName",
        "distrPhone",
        "distrFax",
        "distrEmail",
        "distrNationwide",
        "distrNationwideExcept",
        "distrExceptionsHide",
        "distrExceptionsShow"
      ]}}) {
        nodes {
          ...AllLocalesLabels
        }
      }
    }`
  );

  return parseLabels(labels.nodes, locale);
}
