import React, { ReactElement } from 'react';

import {
  item as cssItem,
  defaultOption as cssDefaultOption,
  unavailable as cssUnavailable,
  selected as cssSelected
} from './alphabet-selector.module.css';

export function isCyrillic (letter: string): boolean {
  return CYRILLIC_ALPHABET.indexOf(letter) !== -1;
}

const LATIN_ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const CYRILLIC_ALPHABET = 'АБВГДЕЖЗИКЛМНОПРСТУФХЦЧШЩЭЮЯ';

type AlphabetSelectorCallback = (letter: string) => void;
type AlphabetSelectorArg = {
  selected: string;
  available: string[];
  onClick: AlphabetSelectorCallback;
  isCyrillic: boolean;
};

export function AlphabetSelector({ selected, available, onClick, isCyrillic}: AlphabetSelectorArg): ReactElement {
  const ALPHABET = isCyrillic ? CYRILLIC_ALPHABET : LATIN_ALPHABET;
  const options = [];
  for (const letter of ALPHABET) {
    options.push(letter);
  }
  return (
    <div className = { `w3-bar`}>
      { options.map((o, i) =>
        <div className = {`w3-bar-item w3-padding-small`} key = { i.toString() }>
          <div className = { cssItem }>
            <a
              className = { available.indexOf(o) === -1
                ? cssUnavailable
                : o === selected ? cssSelected : cssDefaultOption
              }
              onClick = { o !== selected && onClick ? () => onClick(o) : undefined }
            >
              { o }
            </a>
          </div>
        </div>)
      }
    </div>
  );
}
